<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>


  export default {
    name: 'App'
  };
</script>

<style lang="scss" scoped>
  .nav {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
    background: #FFFFFF;

  }
  #app{
     position: relative;

  }
</style>

import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import '@/assets/css/base.scss';
import * as request from '@/plugin/request';
import Toast from './components/toast/index';
import './components/toast/index.css';
import {Form,FormItem,Input} from 'element-ui';
import myplugin from './plugin/myplugin'
Vue.config.productionTip = false;
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$request = request;
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Toast);
Vue.use(myplugin)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

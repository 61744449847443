<template>
  <div class="navlist">
    <div class="item" v-for="(item,index) in list" :key="index">
      <div class="title" @click="index < list.length-1 && gopage(item.url)">{{item.title}}</div>
      <div v-if="index < list.length-1">/</div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
       list:{
           type:Array,
           default:[]
       }
    },
  data() {
    return {};
  },
  watch:{
      '$route':{
          handler(path){

          },
          immediate:true
      }
  },
  methods:{
      gopage(url){
          this.$router.push({path:url})
      }
  }
};
</script>
<style lang='scss'>
.navlist {
  width: 100%;
  max-width: 1400px;
  min-width: 1200px;

  display: flex;
  align-items: center;
  color: #666666;
  margin: 10px auto;
  box-sizing: border-box;
  .title {
    font-size: 12rpx;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-right: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
  .item {
    display: flex;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/personalCenter',
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
      },
      {
        path: '/informationPerfect',
        name: 'informationPerfect',
        component: () => import(/* webpackChunkName: "informationPerfect" */ '../views/informationPerfect.vue')
      },
      {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import(/* webpackChunkName: "personalCenter" */ '../views/personalCenter.vue')
      },
      {
        path: '/detail',
        name: 'detail',
        component : () => import(/* webpackChunkName: "detail" */ '../views/detail.vue')
      },
      {
        path: '/modifyInformation',
        name: 'modifyInformation',
        component : () => import(/* webpackChunkName: "modifyInformation" */ '../views/modifyInformation.vue')
      }
    ]
  }
];
// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
